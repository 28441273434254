<div class="container">
    <div class="input-group-search my-2">
        <div class="input-wrapper">
            <input class="input-control form-control" id="searchTerm" name="searchTerm"
                   [(ngModel)]="inputSearch" placeholder="{{'GLOBAL.SEARCH' | translate}}"/>
            <span class="clear-icon" (click)="clearSearch()">&times;</span>
        </div>
    </div>
    <table class="table text-center" id="printers" >
        <thead>
        <tr>
            <th>{{'PRINTERS.PLACE'| translate}}</th>
            <th>{{'PRINTERS.CHECK_TYPE'| translate}}</th>
            <th>{{'PRINTERS.PRINTER' | translate}}</th>
            <th>{{'ORDER_HISTORY.ACTION' | translate}}</th>
        </tr>
        </thead>
        <tbody>
        <ng-container  *ngFor="let obj of printers | customSearchPipe: { search: inputSearch, searchBy: ['place.name', 'check_type'] }; index as i ">
            <tr>
                <td >{{obj?.place.name}}</td>
                <td *ngIf="obj.check_type === 1">{{'PRINTERS.CLIENT_CHECK'| translate}}</td>
                <td *ngIf="obj.check_type === 2">{{'PRINTERS.KITCHEN_CHECK'| translate}}</td>
                <td *ngIf="obj.check_type === 4">{{'PRINTERS.HOT_KITCHEN_CHECK'| translate}}</td>
                <td *ngIf="obj.check_type === 8">{{'PRINTERS.DELIVERY_RECEIPT'| translate}}</td>
                <td *ngIf="obj.check_type === 16">{{'PRINTERS.DELIVERY_PACKING'| translate}}</td>
                <td><input type="text" class="form-control " [(ngModel)]="obj.name"></td>
                <td>
                    <button class="btn btn-primary " (click)="updatePrinters(obj.id, obj)" >{{'GLOBAL.UPDATE'
                        | translate}}</button>
                </td>
            </tr>
        </ng-container>
        </tbody>
    </table>


</div>
