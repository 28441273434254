import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable, PipeTransform} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {DecimalPipe} from '@angular/common';
import {Router} from '@angular/router';
import {debounceTime, delay, switchMap, tap} from 'rxjs/operators';
import {SortColumn, SortDirection} from '../../../quick-sale/all-quick-sale/sortable.directive';
import {AppApiUrls} from '../../../app.api.urls';
import {CitiesCallCenter, DeliveryStatus, ListeningBinotel, Orders} from '../../../quick-sale/all-quick-sale/interface';
import {NgbCalendar, NgbDate, NgbDateParserFormatter, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Alltoasts} from '../../../toasts/alltoasts';
import {DateService} from '../../@date/date.service';

interface SearchResult {
    orders: Orders[];
    total: number;
}

interface getOrderFromSite {
    lastUpdatedAt: any;
    lastId: string;
}

interface State {
    page: number;
    pageSize: number;
    searchTerm: string;
    onlyOrderNumber: boolean;
    sortColumn: SortColumn;
    sortDirection: SortDirection;
}

const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;


function sort(orders: Orders[], column: SortColumn, direction: string): Orders[] {
    if (direction === '' || column === '') {
        return orders;
    } else {
        return [...orders].sort((a, b) => {
            const res = compare(a[column], b[column]);
            return direction === 'asc' ? res : -res;
        });
    }
}


function matches(order: Orders, term: string, pipe: PipeTransform) {
    return order.street.toLowerCase().includes(term.toLowerCase())
        || order.phone.toLowerCase().includes(term.toLowerCase())
        || pipe.transform(order.order_number).includes(term)
        || pipe.transform(order.total).includes(term)
        || order.comment.toLowerCase().includes(term.toLowerCase());
}

@Injectable({providedIn: 'root'})
export class AllQuicksaleService {
    public ordersFromWebSite: any = [];
    public callsFromWebSite: any = [];
    public placeFromSelect: any = localStorage.getItem('place');
    public AllOrders: any = [];
    // tslint:disable-next-line:typedef adjacent-overload-signatures variable-name
    private _loader$ = new BehaviorSubject<boolean>(true);
    // tslint:disable-next-line:typedef adjacent-overload-signatures variable-name
    private _search$ = new Subject<void>();
    // tslint:disable-next-line:typedef adjacent-overload-signatures variable-name
    private _orders$ = new BehaviorSubject<Orders[]>([]);
    // tslint:disable-next-line:typedef adjacent-overload-signatures variable-name
    private _total$ = new BehaviorSubject<number>(0);
    // tslint:disable-next-line:typedef adjacent-overload-signatures variable-name
    // tslint:disable-next-line:variable-name
    _state: State = {
        page: 1,
        pageSize: 50,
        searchTerm: '',
        onlyOrderNumber: false,
        sortColumn: '',
        sortDirection: ''
    };
    ordersFromWS: any;
    private paramsForOrdersFromWS: getOrderFromSite = {
        lastUpdatedAt: null,
        lastId: '0'
    };
    public paramsForOrders: getOrderFromSite = {
        lastUpdatedAt: '',
        lastId: ''
    };
    timerForGetAllData: any;
    _cashier = false;
    _filterAll = false;
    checkBox: any;
    checkBoxStatus: any;
    loader: boolean;
    checkboxID: string;

    constructor(private pipe: DecimalPipe,
                private http: HttpClient,
                private formatter: NgbDateParserFormatter,
                private router: Router, private calendar: NgbCalendar,
                private Alltoasts: Alltoasts,
                public dateService: DateService,
                public modalService: NgbModal) {
        this._search$.pipe(
            tap(() => this._loader$.next(true)),
            debounceTime(200),
            switchMap(() => this._search()),
            delay(200),
            tap(() => this._loader$.next(false))
        ).subscribe(result => {
            this._orders$.next(result.orders);
            this._total$.next(result.total);
        });
        this.date = calendar.getToday();
        this._search$.next();
    }


    // tslint:disable-next-line:typedef
    get orders$() {
        return this._orders$.asObservable();
    }

    get date(): NgbDate {
        return this.dateService.model;
    }

    set date(date) {
        this.paramsForOrders.lastUpdatedAt = '';
        this.paramsForOrders.lastId = '';
        this.dateService.model = date;
    }

    get cashier(): boolean {
        return this._cashier;
    }

    get filterAll(): boolean {
        return this._filterAll;
    }

    // tslint:disable-next-line:typedef
    get total$() {
        return this._total$.asObservable();
    }

    // tslint:disable-next-line:typedef
    get loader$() {
        return this._loader$.asObservable();
    }

    // tslint:disable-next-line:typedef
    get page() {
        return this._state.page;
    }

    // tslint:disable-next-line:typedef
    get pageSize() {
        return this._state.pageSize;
    }
    get onlyOrderNumber(): boolean {
        return this._state.onlyOrderNumber;
    }


    // tslint:disable-next-line:typedef
    get searchTerm() {
        return this._state.searchTerm;
    }

    // tslint:disable-next-line:typedef adjacent-overload-signatures
    set page(page: number) {
        this._set({page});
    }

    // tslint:disable-next-line:typedef adjacent-overload-signatures
    set pageSize(pageSize: number) {
        this._set({pageSize});
    }

    // tslint:disable-next-line:adjacent-overload-signatures
    set onlyOrderNumber(onlyOrderNumber: boolean) {
        this._set({onlyOrderNumber});
    }

    // tslint:disable-next-line:typedef adjacent-overload-signatures
    set searchTerm(searchTerm: string) {
        this._set({searchTerm});
    }

    set sortColumn(sortColumn: SortColumn) {
        this._set({sortColumn});
    }

    set sortDirection(sortDirection: SortDirection) {
        this._set({sortDirection});
    }

    fetchAll = (cashier?, all?, citiesIdArray?) => {
        let orders: any = [];
        this.paramsForOrders.lastId = '';
        this.paramsForOrders.lastUpdatedAt = '';
        return this.getOrders({
            cashier,
            all,
            lastId: this.paramsForOrders.lastId,
            lastUpdatedAt: this.paramsForOrders.lastUpdatedAt
        }).pipe(tap(json => {
            orders = json;
        }, reposnse => console.log(reposnse), () => {
            if (orders.length === 0) {
                this.AllOrders = [];
                this._search$.next();
            } else {
                if (orders.length) {
                    if (this.paramsForOrders.lastId === '') {
                        this.AllOrders = orders;
                    } else {
                        for (const order of orders) {
                            const index = this.AllOrders.findIndex(t => t.id === order.id);
                            if (index !== -1) {
                                this.AllOrders[index] = order;
                            } else if (index === -1) {
                                this.AllOrders.push(order);
                            }
                        }
                    }
                    this.paramsForOrders.lastId = this.AllOrders.reduce((prev, current) => (prev.id > current.id) ? prev : current).id;
                    this.paramsForOrders.lastUpdatedAt = this.AllOrders.reduce((prev, current) => (prev.updated_at > current.updated_at) ? prev : current).updated_at;
                    this._search$.next();
                }
            }
            this.ordersFromWS = this.ordersFromSite(citiesIdArray).subscribe();
            this.timer(citiesIdArray);
        }));
    };

    getOrders(filterParams: {
        cashier?: boolean;
        all?: boolean;
        lastUpdatedAt: number;
        lastId: string;
    }): Observable<Orders[]> {
        const params: any = (this.placeFromSelect === 'null') ? {
                from: this.formatter.format(this.date),
                to: this.formatter.format(this.date),
                city_id: localStorage.getItem('city'),
                lastUpdatedAt: filterParams.lastUpdatedAt,
                lastId: filterParams.lastId,
                filters: (this.cashier === true && !this.filterAll) ? [{
                    column: 'pickupcheck',
                    value: 1
                }] : null
            }
            : {
                from: this.formatter.format(this.date),
                to: this.formatter.format(this.date),
                place_id: this.placeFromSelect,
                city_id: localStorage.getItem('city'),
                lastUpdatedAt: filterParams.lastUpdatedAt,
                lastId: filterParams.lastId,
                filters: (this.cashier === true && !this.filterAll) ? [{
                    column: 'pickupcheck',
                    value: 1
                }] : null
            };
        return this.http.post<Partial<Orders[]>>(AppApiUrls.GetAllOrders, params);
    }

    timer = (citiesIdArray) => {
        clearTimeout(this.timerForGetAllData);
        this.timerForGetAllData = setTimeout(() => {
            const allOrders = this.fetchAll('', '', citiesIdArray).subscribe(() => null, () => null, () => allOrders.unsubscribe());
        }, 10000);
    };

    toCourierControl = (orderId) => {
        return this.http.post(AppApiUrls.CourierNew + orderId, {}).pipe(tap(json => {
        }, () => this.Alltoasts.showError(), () => this.Alltoasts.showSuccess()));
    };

    // tslint:disable-next-line:typedef adjacent-overload-signatures
    private _set(patch: Partial<State>) {
        Object.assign(this._state, patch);
        this._search$.next();
    }

    private filterByOrderNumber(orders: Orders[], searchTerm: string): Orders[] {
        return orders.filter(order => order.order_number.toString().startsWith(searchTerm));
    }

    private _search(): Observable<SearchResult> {
        const {sortColumn, sortDirection, pageSize, page, searchTerm} = this._state;

        // 1. sort
        let orders = sort(this.AllOrders, sortColumn, sortDirection);

        // 2. filter
        orders = orders.filter(order => matches(order, searchTerm, this.pipe));
        if (this.onlyOrderNumber){
            orders = this.filterByOrderNumber(orders, searchTerm);
        }
        const total = orders.length;

        // 3. paginate
        orders = orders.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
        return of({orders, total});
    }

    ordersFromSite = (citiesIdArray?) => {
        let orders: any = [];
        return this.http.get(AppApiUrls.orderFromSite(), {
            params: {
                'city_group[]': citiesIdArray != null ? citiesIdArray : [localStorage.getItem('city')],
                city_id: localStorage.getItem('city'),
                lang: 'ru',
                source: localStorage.getItem('type')
            }
        }).pipe(tap(json => {
            orders = json;
        }, reposnse => console.log(reposnse), () => {
            this.ordersFromWebSite = orders.data.orders;
            this.callsFromWebSite = orders.data.callback_requests;
            this.ordersFromWS.unsubscribe();
        }));
    };

    checkOrderFromWS = (id, mode?) => {
        if (mode) {
            this.updateOrderFromWSnew(id, 'processing', true).subscribe();
        } else {

            return this.http.get<any>(AppApiUrls.WorkWithOrdersFromWS + id, {}).pipe(tap(json => {
                if (json.state === 'pending') {
                    this.ordersFromWebSite[this.ordersFromWebSite.findIndex(t => t.id == id)].state = 'processing';
                } else {
                    this.ordersFromWebSite[this.ordersFromWebSite.findIndex(t => t.id == id)].state = json.state;
                }
            }, reposnse => console.log(reposnse), () => {
                this.updateOrderFromWS(id, 'processing', true).subscribe();
            }));
        }
    };
    updateOrderFromWS = (id, status, state?) => {
        return this.http.put<any>(AppApiUrls.WorkWithOrdersFromWS + id, {
            state: status
        }).pipe(tap(json => {
        }, reposnse => console.log(reposnse), () => {
            if (state) {
                this.router.navigate([`/quick-sale/site/${id}`]).then(r => {
                });
            }
            if (status === 'canceled') {
                this.ordersFromWebSite.splice(this.ordersFromWebSite.findIndex(t => t.id == id), 1);
            }
        }));
    };

    updateOrderFromWSnew = (id, status, state?) => {
        return this.http.put<any>(AppApiUrls.WorkWithOrdersFromWS + id, {
            state_back: status
        }).pipe(tap(json => {
        }, reposnse => console.log(reposnse), () => {
            if (state) {
                this.router.navigate([`/quick-sale/site/${id}`]).then(r => {
                });
            }
            if (status === 'canceled') {
                this.ordersFromWebSite.splice(this.ordersFromWebSite.findIndex(t => t.id == id), 1);
            }
        }));
    };

    applyStatus = (id, content) => {
        this.loader = true;
        const body = {
            pickup_pay: localStorage.getItem('id'),
            city: localStorage.getItem('city')
        };
        return this.http.put<Orders>(AppApiUrls.WorkWithOrder + id,
            body)
            .subscribe({
                next: value => this.checkBox = value,
                complete: () => {
                    if (this.checkBox.data !== 'not_need') {
                        this.Alltoasts.showSuccess();
                        this.AllOrders[this.AllOrders.findIndex(t => t.id === id)].pickup_pay = 1;
                        this._search$.next();
                        if (this.checkBox.data.checkbox_status === 'CREATED') {
                            this.checkStatusRRO(content);
                        } else if (this.checkBox.data.checkbox_status === 'DONE') {
                            this.loader = false;
                            this.modalService.open(content);
                        } else if (this.checkBox.data.checkbox_status === 'ERROR') {
                            this.loader = false;
                            this.Alltoasts.showDanger('Виникла помилка, відправте повторно до РРО');
                        }
                    } else {
                        this.loader = false;
                    }
                }
            });
    };

    checkStatusRRO = (content, checkboxId?) => {
        let id: number;
        if (checkboxId) {
            id = checkboxId;
        } else {
            id = this.checkBox.data.checkbox_id;
        }
        this.loader = true;
        this.http.get(AppApiUrls.checkboxCheckOrder() + id).subscribe({
            next: value => this.checkBoxStatus = value,
            complete: () => {
                    this.checkboxID = this.checkBoxStatus.data.id;
                    if (this.checkBoxStatus.data.status === 'CREATED') {
                        setTimeout(() => {
                            this.checkStatusRRO(content, id);
                        }, 2000);
                    } else if (this.checkBoxStatus.data.status === 'DONE') {
                        this.loader = false;
                        this.modalService.open(content);
                    } else if (this.checkBoxStatus.data.status === 'ERROR') {
                        this.loader = false;
                        this.Alltoasts.showDanger('Виникла помилка, відправте повторно до РРО');
                    }
            }
        });
    };

    deliveryStatus(id): Observable<DeliveryStatus> {
        return this.http.get<DeliveryStatus>(AppApiUrls.deliveryStatus() + id, {
            params: {
                crm: true
            }
        });
    }

    listeningBinotel(cityId, phone): Observable<ListeningBinotel[]> {
        return this.http.get<ListeningBinotel[]>(AppApiUrls.listeningBinotel(), {
            params: {
                city_id: cityId,
                phone_number: phone
            }
        });
    }

    getCitiesCallCenter(): Observable<CitiesCallCenter> {
        const id = localStorage.getItem('group');
        return this.http.get<CitiesCallCenter>(AppApiUrls.callCenterCities() + id);
    }

    getOrdersNew(id): Observable<any> {
        return this.http.get<any>(AppApiUrls.WorkWithOrdersFromWS + id);
    }


    sendCheckBox(id, terminalPayLoad?): Observable<any> {
        console.log(terminalPayLoad);
        const body = {
            place_id: localStorage.getItem('place'),
            user_id: localStorage.getItem('id'),
            terminal_data: terminalPayLoad !== undefined || null
                ? {
                    terminal_id: terminalPayLoad.terminal_id,
                    card_mask: terminalPayLoad.card_mask,
                    bank_name: terminalPayLoad.bank_name,
                    owner_name: terminalPayLoad.owner_name,
                    payment_system: terminalPayLoad.payment_system,
                    receipt_no: terminalPayLoad.receipt_no,
                    auth_code: terminalPayLoad.auth_code,
                    rrn: terminalPayLoad.rrn,
                }
                : null
        };
        return this.http.post<any>(AppApiUrls.sendCheckOrder() + id, body);
    }

    putCallBackRequest(id: number, body: any): Observable<any>{
        return this.http.put<any>(AppApiUrls.callbackRequest() + '/' + id, body);
    }

    postQualityControl(body: any): Observable<any>{
        return this.http.post<any>(AppApiUrls.qualityControl(), body);
    }

    getCheckboxDevices(): Observable<any>{
        return this.http.get<any>(AppApiUrls.checkBoxGetDevices());
    }

    getCheckboxPingDevice(id): Observable<any>{
        return this.http.get<any>(AppApiUrls.checkBoxPingDevice(id));
    }

    postCheckBoxPurchaseDevice(id: number, body: any): Observable<any>{
        return this.http.post<any>(AppApiUrls.checkBoxPurchaseDevice(id), body);
    }


}
