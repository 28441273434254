import {environment} from '../environments/environment';
import {ZoneReserve} from "./zone-reserve/interface/zone-reserve.interface";

export class AppApiUrls {
    // Домен сервера
    public static NewDomain: string = environment.url;
    // Получение даты с сервера
    public static GetDate = `${AppApiUrls.NewDomain}api/v1/date`;
    // Получение поваров для панели упаковки по place и city
    public static GetCookersPackagePanel = `${AppApiUrls.NewDomain}api/v1/packagepanel/cookers`;
    // Получение упаковщиков для панели упаковки по place и city
    public static GetPackagersPackagePanel = `${AppApiUrls.NewDomain}api/v1/packagepanel/packagers`;
    // Получение всех категорий
    public static GetAllCategories = `${AppApiUrls.NewDomain}api/v1/quicksale/categories`;
    // Получение всех инструкций
    public static GetAllManuals = `${AppApiUrls.NewDomain}api/v1/manual`; // +
    // Авторизация
    public static Auth = `${AppApiUrls.NewDomain}api/v1/user/auth`;
    // Получение списка кассового учета
    public static GetCashAccounting = `${AppApiUrls.NewDomain}api/v1/cash_accounting/get`; // +
    // Получение списка затрат
    public static GetCostType = `${AppApiUrls.NewDomain}api/v1/cash_accounting/transaction_type`;
    // Подтверждение Инкассации STATUS=1 + ID
    public static ApplyCollection = `${AppApiUrls.NewDomain}api/v1/cash_accounting/confirm`;
    // Списание и постановка денег в кассу
    public static InserOrWasteCash = `${AppApiUrls.NewDomain}api/v1/cash_accounting/insert`;
    // Смена метода оплаты
    public static UpdatePaymentMethod = `${AppApiUrls.NewDomain}api/v1/cash_accounting/update_payment_method`;
    // Получение списка купюр
    public static GetBills = `${AppApiUrls.NewDomain}api/v1/cash_accounting/bills/`;
    // Получение списка всех отделений
    public static GetAllPlaces = `${AppApiUrls.NewDomain}api/v1/places`;
    // Получение всей продукции по городу
    public static ProductCity = `${AppApiUrls.NewDomain}api/v1/quicksale/products`;
    // Получение контрагентов для подтверждения
    public static GetContrForConfirmWaybills = `${AppApiUrls.NewDomain}api/v1/storage/contragent`;
    // Создание накладной
    public static CreateWaybill = `${AppApiUrls.NewDomain}api/v1/invoice/new`;
    // Получение накладных и контрагентов
    public static GetWaybillsByContr = `${AppApiUrls.NewDomain}api/v1/invoice/get`;
    // Обновление накладных
    public static WaybillsConfirm = `${AppApiUrls.NewDomain}api/v1/invoice/update`;
    // Все накладные по отделению
    public static AllWaybills = `${AppApiUrls.NewDomain}api/v1/invoice/all`;
    // Обновление статуса накладной
    public static WaybillsUpdateDocumentStatus = `${AppApiUrls.NewDomain}api/v1/invoice/update/status`;
    // Создание инвентаризации
    public static CreateCollection = `${AppApiUrls.NewDomain}api/v1/stock/new`;
    // Получение дат инвентаризации
    public static GetDatesCollection = `${AppApiUrls.NewDomain}api/v1/stock/dates`;
    // Получение информации о инвентаризации
    public static GetInfoAboutCollection = `${AppApiUrls.NewDomain}api/v1/stock/get_stock`;
    // Обновление инвентаризации
    public static CollectionUpdate = `${AppApiUrls.NewDomain}api/v1/stock/update_stock`;
    // Получение районов
    public static GetAllDistricts = `${AppApiUrls.NewDomain}api/v1/quicksale/districts`;
    // Получение зон
    public static GetAllZones = `${AppApiUrls.NewDomain}api/v1/quicksale/zones`;
    // Плановый расход
    public static writeOff = `${AppApiUrls.NewDomain}api/v1/report/writeOff`;
    // Фактический расход
    public static ReportStocks = `${AppApiUrls.NewDomain}api/v1/report/stock`;
    // Сравнение план факт
    public static writeOffDiff = `${AppApiUrls.NewDomain}api/v1/report/writeOffDiff`;
    // Информация о заказе
    public static getInfoAboutOrder = `${AppApiUrls.NewDomain}api/v1/quicksale/orderInfo`;
    // Работа с заказом
    public static WorkWithOrder = `${AppApiUrls.NewDomain}api/v1/order/`;
    // Маршрутные листы
    public static getAllRouteLists = `${AppApiUrls.NewDomain}api/v1/getRouteList/`;
    // Маршрутний лист
    public static getRouteList = `${AppApiUrls.NewDomain}api/v1/routeList/`;
    // Заказ для редактирования в мл
    public static getOrderForEditInRouteList = `${AppApiUrls.NewDomain}api/v1/getOrder/`;
    // Работа с заказами с сайта
    public static WorkWithOrdersFromWS = `${AppApiUrls.NewDomain}api/v1/orders/`;
    // Полученіе заказа по номеру телефона
    public static FillByPhone = `${AppApiUrls.NewDomain}api/v1/orderByPhone`;
    // Отчет по себестоимости
    public static ReportCostPrice = `${AppApiUrls.NewDomain}api/v1/report/costPrice`;
    // Повторная отправка в КК
    public static CourierNew = `${AppApiUrls.NewDomain}api/v1/quicksale/courierControl/`;
    // Отчет по маршрутным листам
    public static ReportRouteList = `${AppApiUrls.NewDomain}api/v1/report/routeLists`;
    // Получение списка заказов
    public static GetAllOrders = `${AppApiUrls.NewDomain}api/v1/quicksale/ordersList`;
    // сумма курьер контроль доставка
    public static routesSum = `${AppApiUrls.NewDomain}api/v1/routesSum`;
    // Модули сайта
    public static SiteModules = `${AppApiUrls.NewDomain}api/v1/site/modules`;
    // Получение ответов на выключение сайта
    public static AnswerTurnOffSite = `${AppApiUrls.NewDomain}api/v1/pages`;
    // Отчет по упаковщикам
    public static ReportPackagers = `${AppApiUrls.NewDomain}api/v1/report/packagers_points`;
    // Отчет по помощникам
    public static ReportAssistants = `${AppApiUrls.NewDomain}api/v1/report/assistants`;
    // Обновление по журнал ID
    public static turnoverJournal = `${AppApiUrls.NewDomain}api/v1/turnover_journal/`;
    // CRUD Terminal
    public static Terminals = `${AppApiUrls.NewDomain}api/v1/terminals/`;
    // Модули сайтa
    static siteModules = (city: number) => `${AppApiUrls.NewDomain}api/v1/shops/2/cities/${city}/modules`;
    // Включение/Выключение сайта
    static enableSite = (id, type) => `${AppApiUrls.NewDomain}api/v1/shops/2/cities/${id}/${type}`;
    // Включен или выключен сайт
    static siteInfo = (id) => `${AppApiUrls.NewDomain}api/v1/shops/2/cities/${id}/shop_info`;
    // Обновление страницы
    static updatePage = (id) => `${AppApiUrls.NewDomain}api/v1/shops/2/cities/${id}/updatePage`;
    // Информация про упаковку заказа
    static GetInfoPackages = (id?) => {
        return (id) ? `${AppApiUrls.NewDomain}api/v1/packages/${id}` : `${AppApiUrls.NewDomain}api/v1/packages/`;
    }
    // Получение всех пользователей
    static getAllUsers = (id?) => {
        return (id) ? `${AppApiUrls.NewDomain}api/v1/users/${id}` : `${AppApiUrls.NewDomain}api/v1/users/`; //TODO не забыть убрать!!!
    }
    // Информация про отделения
    static GetPlaces = (id?) => {
        return (id) ? `${AppApiUrls.NewDomain}api/v1/places/${id}` : `${AppApiUrls.NewDomain}api/v1/places/`;
    }
    // Информация про города
    static GetCities = (id?) => {
        return (id) ? `${AppApiUrls.NewDomain}api/v1/cities/${id}` : `${AppApiUrls.NewDomain}api/v1/cities/`;
    }
    // Отчет поваров
    static reportCookers = () => `${AppApiUrls.NewDomain}api/v1/report/cookers_points`;
    // Смены для кассового учета
    static shiftList = () => `${AppApiUrls.NewDomain}api/v1/cash_accounting/shift_list`;
    // Информация о смене находится ли на ней пользователь
    static shiftInfo = () => `${AppApiUrls.NewDomain}api/v1/cash_accounting/shift_info`;
    // НАчать смену в КУ
    static startShift = () => `${AppApiUrls.NewDomain}api/v1/cash_accounting/start_shift`;
    // Закончить смену в КУ
    static endShift = () => `${AppApiUrls.NewDomain}api/v1/cash_accounting/end_shift`;
    // Создать трату в КУ
    static cashTurnover = () => `${AppApiUrls.NewDomain}api/v1/cash_turnover`;
    // Получить все транзакции для КУ
    static getAllTransactions = () => `${AppApiUrls.NewDomain}api/v1/cash_accounting/transaction_list`;
    // Получение информации по кассе
    static getCACurrentCash = () => `${AppApiUrls.NewDomain}api/v1/cash_accounting/current_cash`;
    // Зарплаты
    static WagesCA = () => `${AppApiUrls.NewDomain}api/v1/wages`;
    // Suppliers
    static Suppliers = () => `${AppApiUrls.NewDomain}api/v1/invoices`;
    // SuppliersNewPayment
    static SuppliersNewPayment = () => `${AppApiUrls.NewDomain}api/v1/invoices_payment`;
    // Долги
    static Debts = () => `${AppApiUrls.NewDomain}api/v1/debts/`;
    // вернуть Долг
    static returnDebts = () => `${AppApiUrls.NewDomain}api/v1/cash_accounting/add_debt_pay/`;
    // Информация о городах
    static CitiesInfo = (id?) => (id) ? `${AppApiUrls.NewDomain}api/v1/cities/${id}` : `${AppApiUrls.NewDomain}api/v1/cities/`;
    // Информация о городах
    static CityInfo = () => `${AppApiUrls.NewDomain}api/app/city_info`;
    // Начать смену для инкассаций
    static StartShiftForCollection = () => `${AppApiUrls.NewDomain}api/v1/collections`;
    // Создать инкассацию
    static createCollection = () => `${AppApiUrls.NewDomain}api/v1/collection_cash`;
    // Список людей получивших ЗП
    static doneWages = () => `${AppApiUrls.NewDomain}api/v1/wage/done_users`;
    // Получение номеров-дубликатов с днями рождения
    static duplicateBirthday = () => `${AppApiUrls.NewDomain}api/v1/quicksale/ordersListBirthday`;
    // Маршрутные листы по районам
    static districtRouteList = () => `${AppApiUrls.NewDomain}api/v1/route_districts/`;
    // Получение данных о камерах
    static cameras = () => `${AppApiUrls.NewDomain}api/v1/cameras/`;
    // Сохранение отредактированных данных
    static historyEditedDocuments = () => `${AppApiUrls.NewDomain}api/v1/quicksale/edit_history`;
    // Получение списка должностей
    static profession = () => `${AppApiUrls.NewDomain}api/v1/profs/`;
    // Получение списка продуктов
    static products = () => `${AppApiUrls.NewDomain}api/v1/crm_products/`;
    // Получение данные инстаграма по продукту
    static instagram = () => `${AppApiUrls.NewDomain}api/v1/instagram/`;
    // Обновление картинки для инстаграма
    static instagramImg = () => `${AppApiUrls.NewDomain}api/v1/instagram/update_image/`;
    // Доступ к assets
    static getAssets = () => `${AppApiUrls.NewDomain}assets/instagram/`;
    // Cash transfer to next cashier
    static cashTransfer = () => `${AppApiUrls.NewDomain}api/v1/collection_transfers`;
    // Work with cash transactions
    static cashTransactions = () => `${AppApiUrls.NewDomain}api/v1/cash_transactions`;
    // Get all info in CA about terminal
    static terminalCash = () => `${AppApiUrls.NewDomain}api/v1/cash_accounting/terminal_cash`;
    // Cash info in QS and RL
    static getCashInfo = () => `${AppApiUrls.NewDomain}api/v1/cash_accounting/cash_report`;
    // Получение отзывов с ботов
    static getReviews = () => `${AppApiUrls.NewDomain}api/v1/reviews`;
    // Очистка данных входа
    static userDataReset = () => `${AppApiUrls.NewDomain}api/v1/session/`;
    // Получение списка призовых роллов
    static getWinnerList = () => `${AppApiUrls.NewDomain}api/v1/winner_list/`;
    // Удаление инвентаризации
    static deleteCollection = () => `${AppApiUrls.NewDomain}api/v1/report/stock_delete/`;
    // Получение чеков и денег по персоналу
    static getReportPersonnel = () => `${AppApiUrls.NewDomain}api/v1/report/cookers_info`;
    // Включение/Выключение сайта пиццы
    static enableSitePizza = (id, type) => `${AppApiUrls.NewDomain}api/v1/shops/1/cities/${id}/${type}`;
    // Включен или выключен сайт пиццы
    static siteInfoPizza = (id) => `${AppApiUrls.NewDomain}api/v1/shops/1/cities/${id}/shop_info`;
    // Обновление пиццы
    static updatePagePizza = (id) => `${AppApiUrls.NewDomain}api/v1/shops/1/cities/${id}/updatePage`;
    // Получение премии по помощникам
    static getReportAssistants = () => `${AppApiUrls.NewDomain}api/v1/report/assistants_new`;
    // Получение списка всех модулей
    public static GetAllModules = () => `${AppApiUrls.NewDomain}api/v1/modules`;
    // Получение техкарт
    public static getTechCards = () => `${AppApiUrls.NewDomain}api/v1/card`;
    // Create debt from RL
    public static createDebtRL = `${AppApiUrls.NewDomain}api/v1/cash_accounting/debt_route_list/`;
    // Получение отделений по id
    public static getPlacesById = () => `${AppApiUrls.NewDomain}api/v1/places/for_user/`;
    // Печать текста на принтеры
    public static printMessage = () => `${AppApiUrls.NewDomain}api/v1/print_management/message`;
    // Получение тех карт с ингридиентами
    public static allTechCards = () => `${AppApiUrls.NewDomain}api/v1/documents_info/card_products`;
    // Получение ингридиентов
    public static ingredients = () => `${AppApiUrls.NewDomain}api/v1/documents_info/ingredients`;
    // Получение полуфабрикатов
    public static semifinishes = () => `${AppApiUrls.NewDomain}api/v1/documents_info/semifinishes`;
    // Получение полуфабрикатов
    public static reportsCouriers = () => `${AppApiUrls.NewDomain}api/v1/report/courier`;
    // RL builder
    static rlBuilder = `${AppApiUrls.NewDomain}api/v1/routeList`;
    // Отслеживание заказа
    public static deliveryStatus = () => `${AppApiUrls.NewDomain}api/v1/delivery_status/`;
    // Отслеживание заказа
    public static listeningBinotel = () => `${AppApiUrls.NewDomain}api/v1/binotel/listen_calls`;
    // Получение городов для кол центра
    public static callCenterCities = () => `${AppApiUrls.NewDomain}api/v1/call-center/group/`;
    // Отчет по операторам
    public static ReportOperators = () => `${AppApiUrls.NewDomain}api/v1/report/operators`;
    // Отчет по операторам
    public static DeliveryTime = () => `${AppApiUrls.NewDomain}api/v1/delivery_time`;
    // Получение графика работы персонала
    public static GetSchedulePersonnel = () => `${AppApiUrls.NewDomain}api/v1/personnel_schedule`;
    // Получение информации про тесты по id
    public static getInfoPersonnelTests = () => `${AppApiUrls.NewDomain}api/v1/personal_tests/available/`;
    // Получение теста по id
    public static getPersonnelTest = () => `${AppApiUrls.NewDomain}api/v1/personal_tests/test/`;
    // Обновление статуса теста
    public static updateStatePersonnelTest = () => `${AppApiUrls.NewDomain}api/v1/personal_tests/pass`;
    // Создание теста
    public static createPersonnelTest = () => `${AppApiUrls.NewDomain}api/v1/personal_tests/lists/create`;
    // Создание теста
    public static personnelTestList = () => `${AppApiUrls.NewDomain}api/v1/personal_tests/lists`;
    // Результаті тестов
    public static ResultsPersonnelTest = () => `${AppApiUrls.NewDomain}api/v1/personal_tests/results`;
    // Результаті тестов
    public static cityGroup = () => `${AppApiUrls.NewDomain}api/v1/call-center/groups`;
    // Отчет по опозданиям
    public static latenessReport = () => `${AppApiUrls.NewDomain}api/v1/user/visit/report`;
    // История заказов
    public static historyOrders = () => `${AppApiUrls.NewDomain}api/v1/orders`;
    // Статистика по тех поддержке
    public static supportStatistics = () => `${AppApiUrls.NewDomain}api/v1/support/request`;
    // Отчет по опозданиям
    public static statistics = () => `${AppApiUrls.NewDomain}api/v1/sale_statistics`;
    // Отчет по опозданиям
    public static productSold = () => `${AppApiUrls.NewDomain}api/v1/report/sale_items`;
    // Отчет по опозданиям
    public static moduleMainPage = () => `${AppApiUrls.NewDomain}api/v1/modules_for_main_page`;
    // Последний ДР
    public static lastBirthday = () => `${AppApiUrls.NewDomain}api/v1/lastBirthdayByPhone`;
    // Черный лист
    public static blackList = () => `${AppApiUrls.NewDomain}api/v1/black_list`;
    // Проверка статуса чекбокса
    public static checkboxCheckOrder = () => `${AppApiUrls.NewDomain}api/v1/cash_accounting/checkbox/check_order/`;
    // Отправка чека в чекбокс
    public static sendCheckOrder = () => `${AppApiUrls.NewDomain}api/v1/cash_accounting/checkbox/send_order/`;
    // Получение касиров
    public static checkboxCashiers = () => `${AppApiUrls.NewDomain}api/v1/cash_accounting/checkbox/cashiers`;
    // Закрытие смены
    public static closeShift = () => `${AppApiUrls.NewDomain}api/v1/cash_accounting/checkbox/close_shift/`;
    // Открытие смены
    public static openShift = () => `${AppApiUrls.NewDomain}api/v1/cash_accounting/checkbox/open_shift/`;
    // Замовлення з сайту
    public static orderFromSite = () => `${AppApiUrls.NewDomain}api/v1/orders`;
    // Сабміт замовлення у документ швидкого продажу
    public static submitOrderInQS = () => `${AppApiUrls.NewDomain}api/v1/getOrderForQS`;
    // Локальная печать чека
    public static localPrint = () => `${AppApiUrls.NewDomain}api/v1/quicksale/print/`;
    // Зміна принтера
    public static changePrinter = () => `${AppApiUrls.NewDomain}api/v1/places/updatePrinter`;
    // Текст для індексації на сайті
    public static siteIndex = () => `${AppApiUrls.NewDomain}api/v1/site/site_index`;
    // Розрахунок безкоштовний товарів
    public static calcFreeProduct = () => `${AppApiUrls.NewDomain}api/v1/quicksale/free_products`;
    // Включение выключение товара
    public static enableProduct = () => `${AppApiUrls.NewDomain}api/v1/quicksale/updatePrice`;
    // Получение данных о заработной платы
    public static payRoll = () => `${AppApiUrls.NewDomain}api/v1/payroll`;
    // Получение пустой формы для заполнения данных учета зароботной платы
    public static payRollBlank = () => `${AppApiUrls.NewDomain}api/v1/payroll_blank`;
    // Получение всех баннеров
    public static banners = () => `${AppApiUrls.NewDomain}api/v1/banner/get`;
    // Отправка всех доступных баннеров
    public static bannersEnable = () => `${AppApiUrls.NewDomain}api/v1/banner/enable`;
    // Обновить Order number
    public static bannersOrNumberUpdated = () => `${AppApiUrls.NewDomain}api/v1/banner/update_order_number`;
    // Создать баннер
    public static createBanner = () => `${AppApiUrls.NewDomain}api/v1/banner/new`;
    // Удалить баннер
    public static deleteBanner = () => `${AppApiUrls.NewDomain}api/v1/banner/delete`;
    // Получение всех пользователей сайта
    public static siteUsers = () => `${AppApiUrls.NewDomain}api/app/user/site`;
    // Блокировка пользователей сайта
    public static blockUser = () => `${AppApiUrls.NewDomain}api/app/user/block_user`;
    // Запрос для начисления бонусов
    public static addBonuses = () => `${AppApiUrls.NewDomain}api/app/user/bonuses/manual_add`;
    // Создание текста для выключения сайта
    public static createAnswer = () => `${AppApiUrls.NewDomain}api/v1/pages`;
     // Запрос для получения чеков без маршрута
    public static pendingOrders = () => `${AppApiUrls.NewDomain}api/v1/delivery/pending_orders`;
    // Получение списка ожидающих маршрутов
    public static pendingRoutes = () => `${AppApiUrls.NewDomain}api/v1/delivery/pending_routes`;
    // Запрос на изменение чеков в маршруте
    public static setOrders = () => `${AppApiUrls.NewDomain}api/v1/delivery/set_orders`;
    // Запрос на создание пустого маршрута
    public static addNewOrder = () => `${AppApiUrls.NewDomain}api/v1/delivery/new`;
    // Запрос на курьеров на смене (приход/уход)
    public static couriers = () => `${AppApiUrls.NewDomain}api/v1/delivery/couriers`;
    // Запрос на рассчет маршрута
    public static calculate = () => `${AppApiUrls.NewDomain}api/v1/delivery/calculate`;
    // Добавление и удаления заказа в маршрут
    public static addToRoute = () => `${AppApiUrls.NewDomain}api/v1/delivery/route_order`;
    // Запрос на обновление координаты точки
    public static updateOrderLocation = () => `${AppApiUrls.NewDomain}api/v1/delivery/update_order_location`;
    // Запрос на фиксацию посчитанного маршрута
    public static ready = () => `${AppApiUrls.NewDomain}api/v1/delivery/ready`;
    // Запрос на отправку маршрута в работу
    public static sendToWork = () => `${AppApiUrls.NewDomain}api/v1/delivery/send`;
    // Запрос на возвращения маршрута в работу
    public static updateTemplate = () => `${AppApiUrls.NewDomain}api/v1/delivery/template/`;
    // Запрос для отчета по маршрутам
    public static reportRoutes = () => `${AppApiUrls.NewDomain}api/v1/delivery/report/routes`;
    // Запрос для отчета по маршрутам
    public static reportOrders = () => `${AppApiUrls.NewDomain}api/v1/delivery/report/orders`;
    // Запрос для отчета по маршрутам
    public static reportCouriers = () => `${AppApiUrls.NewDomain}api/v1/delivery/report/couriers`;
    // Поучения мобильних увидомлений
    public static mobNotifiations = () => `${AppApiUrls.NewDomain}api/v1/mobile_notifications_schedules`;
    // Добавление картинки в мобильних увидомлений
    public static addNotificationImg = () => `${AppApiUrls.NewDomain}api/v1/mobile_notifications_schedules/image`;
    // Запрос для автообновления маршрута по зонам
    public static autoComplete = () => `${AppApiUrls.NewDomain}api/v1/delivery/autocomplete`;
    // Запрос для Unit
    public static unit = () => `${AppApiUrls.NewDomain}api/v1/unit`;
    // Получение всех не включеных товаров
    public static pendingPrice = () => `${AppApiUrls.NewDomain}api/v1/quicksale/pending_prices`;
    // Запрос для добавления цены товару
    public static createPrice = () => `${AppApiUrls.NewDomain}api/v1/quicksale/create_price`;
    // Получение всех ингредиентов
    public static ingredient = () => `${AppApiUrls.NewDomain}api/v1/ingredient`;
    // Получение всех semifinish
    public static semifinish = () => `${AppApiUrls.NewDomain}api/v1/semifinish`;
    // Все ингридиенты и полуфабрикаты
    public static storageIngredients = () => `${AppApiUrls.NewDomain}api/v1/storage/ingredients`;
    // Получение всех маркеров для товаров
    public static mark = () => `${AppApiUrls.NewDomain}api/v1/mark`;
    // Получение логов системы
    public static systemLogs = () => `${AppApiUrls.NewDomain}api/v1/log`;
    // Получение информации о звонках
    public static binotel = () => `${AppApiUrls.NewDomain}api/v1/binotel/report`;
    // Получение бонусных условий
    public static bonusConditions = () => `${AppApiUrls.NewDomain}api/v1/bonus_conditions`;
    // Получение всех категорий
    public static category = () => `${AppApiUrls.NewDomain}api/v1/category`;
    // Получение всех принетров
    public static printers = () => `${AppApiUrls.NewDomain}api/v1/print_management/printers`;
    // Получение картинок для конструктора сетов
    public static productThumbnail = () => `${AppApiUrls.NewDomain}api/v1/product_thumbnail`;
    // Получение всех операторов
    public static phoneOperator = () => `${AppApiUrls.NewDomain}api/v1/phone_operator`;
    // Получение countries
    public static countries = () => `${AppApiUrls.NewDomain}api/app/countries`;
    // Закрытие маршрута
    public static routeClose = () => `${AppApiUrls.NewDomain}api/v1/delivery/close`;
    // Получение данных о том кто выдал бонусы
    public static reportBonus = () => `${AppApiUrls.NewDomain}api/v1/report/bonus`;
    // Получение причин редактирования
    public static routeListEditPurposes = () => `${AppApiUrls.NewDomain}api/v1/routelist_edit_purposes`;
    // Получение списка редактирований
    public static routeListEditHistory = () => `${AppApiUrls.NewDomain}api/v1/routelist_edit_history`;
    // Получение бесплатных товаров по городу
    public static freeCardProduct = () => `${AppApiUrls.NewDomain}api/v1/free_card_product`;
    // Получение контрагентов
    public static contragent = () => `${AppApiUrls.NewDomain}api/v1/storage/contragent`;
    // Получение списка уволенных
    public static userLeaveList = () => `${AppApiUrls.NewDomain}api/v1/user/leave/list`;
    // Увольнение пользователей
    public static userLeaveAdd = () => `${AppApiUrls.NewDomain}api/v1/user/leave/add`;
    // Возвращение на работу пользователей
    public static userLeaveRemove = () => `${AppApiUrls.NewDomain}api/v1/user/leave/remove`;
    // Получение страниц информации
    public static cityAbout = () => `${AppApiUrls.NewDomain}api/v1/city_about`;
    // Получение городов для селекта для описаний
    public static lang = () => `${AppApiUrls.NewDomain}api/v1/lang`;
    // Получение организаций
    public static checkboxOrganization = () => `${AppApiUrls.NewDomain}api/v1/cash_accounting/checkbox/checkbox_organization`;
    // Получение касс
    public static checkboxCashbox = () => `${AppApiUrls.NewDomain}api/v1/cash_accounting/checkbox/checkbox_cashbox`;
    // Получение Кассиров
    public static checkboxCashier = () => `${AppApiUrls.NewDomain}api/v1/cash_accounting/checkbox/checkbox_cashier`;
    // Получение Настроек
    public static checkboxProperty = () => `${AppApiUrls.NewDomain}api/v1/cash_accounting/checkbox/checkbox_property`;
    public static checkboxPropertyGrouped = () => `${AppApiUrls.NewDomain}api/v1/cash_accounting/checkbox/checkbox_property_grouped`;
    // Получение всех Отделений
    public static getAllPlaces = () =>  `${AppApiUrls.NewDomain}api/v1/places`;
    // Установка webhook
    public static setWebhook = () =>  `${AppApiUrls.NewDomain}api/v1/cash_accounting/checkbox/set_webhook`;
    // Создание отзывов для заказов в ручную
    public static orderReviews = () =>  `${AppApiUrls.NewDomain}api/app/order_reviews`;
    // Запрос для изменения времени вручную
    public static quickSaleDeliveryTime = () =>  `${AppApiUrls.NewDomain}api/v1/quicksale_delivery_time`;
    // Получение всех обучающих видео
    public static sop = () =>  `${AppApiUrls.NewDomain}api/v1/sop`;
    // Получение груп городов
    public static cityGroups = () =>  `${AppApiUrls.NewDomain}api/v1/city_group`;
    // Получение всех запросов на обратный звонок
    public static callbackRequest = () =>  `${AppApiUrls.NewDomain}api/v1/callback_request`;
    // Получение всех уведомлений
    public static news = () =>  `${AppApiUrls.NewDomain}api/v1/news`;
    // Запрос для отправки файлов
    public static newsAttachments = () =>  `${AppApiUrls.NewDomain}api/v1/news_attachment`;
    // Запрос на подтверждение(прочтение)  новости
    public static newsConfirmation = () =>  `${AppApiUrls.NewDomain}api/v1/news_confirmation`;
    // Запрос на получение промокодов
    public static promoCode = () =>  `${AppApiUrls.NewDomain}api/v1/promocode`;
    // Запрос на получение промокодов для отчета
    public static reportPromoCode = () =>  `${AppApiUrls.NewDomain}api/v1/report/promocode`;
    // Получение количества и цен по категориям
    public static orderDetails = () =>  `${AppApiUrls.NewDomain}api/v1/quicksale/order_details`;
    // Получение соцсетей для сайта
    public static social = () =>  `${AppApiUrls.NewDomain}api/v1/socials`;
    // Получение новых поьзователей
    public static newClients = () =>  `${AppApiUrls.NewDomain}api/v1/report/new_clients`;
    // Запрос на получение рекомендованых товаров
    public static recommendedProducts = () =>  `${AppApiUrls.NewDomain}api/v1/recommended_products`;
    // Получение резервных зон
    public static zoneReserve = () =>  `${AppApiUrls.NewDomain}api/v1/zone_reserve`;
    // Получение Всех зон
    public static allZones = () =>  `${AppApiUrls.NewDomain}api/v1/quicksale/zones`;
    // Запрос на получение всех декораций на сайте
    public static decoration = () =>  `${AppApiUrls.NewDomain}api/v1/decoration`;
    // Запрос для редактирования декораций на сайте
    public static decorationAttribute = () =>  `${AppApiUrls.NewDomain}api/v1/decoration_attribute`;
    // Получение статиски по бонусам
    public static bonusStats = () =>  `${AppApiUrls.NewDomain}api/v1/report/bonus_stats`;
    // Запрос для проверки курьеров
    public static deliveryReportViolation = () =>  `${AppApiUrls.NewDomain}api/v1/delivery/report/violation`;
    // Отчет по панели упаковки
    public static reportPackage = () =>  `${AppApiUrls.NewDomain}api/v1/report/package`;
    // Отчет о вкл/выкл сайта
    public static siteStatusHistory = () =>  `${AppApiUrls.NewDomain}api/v1/report/site_status_history`;
    // Запрос для получения рейтинга курьеров
    public static couriersRating = () =>  `${AppApiUrls.NewDomain}api/v1/report/couriers_rating`;
    // Запрос для получения рейтинга поворов
    public static cookersRating = () =>  `${AppApiUrls.NewDomain}api/v1/report/cookers_rating`;
    // Запрос для получения бенчмаркинга
    public static bench = () =>  `${AppApiUrls.NewDomain}api/v1/bench`;
    // Запрос Админки доступа к чат-боту заявок по tg id
    public static messageBotID = () =>  `${AppApiUrls.NewDomain}api/v1/message_bot_id`;
    // Запрос для получения возможно оподавших заказов
    public static possibleDelays = () =>  `${AppApiUrls.NewDomain}api/v1/delivery/possible_delays`;
    // Запрос для набора клиенту
    public static delayRecall = () =>  `${AppApiUrls.NewDomain}api/v1/delivery/delay_recall`;
    // Запрос для получения отчета об оподавших заказах
    public static delayReportRecall = () =>  `${AppApiUrls.NewDomain}api/v1/delivery/report/delay_recall`;
    // Запрос для описания причины опоздания
    public static delayComment = () =>  `${AppApiUrls.NewDomain}api/v1/delivery/delay_comment`;
    //  Запрос на получение прогрессивной шкалы баллов поваров
    public static pointsRange = () =>  `${AppApiUrls.NewDomain}api/v1/points_range`;
    // Запрос для доп товаров
    public static linkedProducts = () =>  `${AppApiUrls.NewDomain}api/v1/quicksale/linked_products`;
    // Запрос на получение связи id товаров
    public static externalGet = () =>  `${AppApiUrls.NewDomain}api/v1/external/get`;
    // Запрос на отправку связи id товаров
    public static externalSet = () =>  `${AppApiUrls.NewDomain}api/v1/external/set`;
    // Категории связанных товаров
    public static productAdditionCategories = () =>  `${AppApiUrls.NewDomain}api/v1/product_addition_categories`;
    // Связанные товары
    public static productAdditions = () =>  `${AppApiUrls.NewDomain}api/v1/product_additions`;
    // Получение списка по городу
    public static tvMediaSchedule = () =>  `${AppApiUrls.NewDomain}api/v1/tv_media_schedule`;
    // Изменение порядка для tv_media_schedule
    public static tvMediaScheduleUpdateOrderNumber = () =>  `${AppApiUrls.NewDomain}api/v1/tv_media_schedule/update_order_number`;
    // Получение модулей
    public static modules = () =>  `${AppApiUrls.NewDomain}api/v1/city/modules`;
    // Получение профессии по модулю
    public static modulesProfs = () =>  `${AppApiUrls.NewDomain}api/v1/city/modules/profs`;
    // Получени всех звонков калцентр
    public static callCenter = () =>  `${AppApiUrls.NewDomain}api/v1/call-center/reports`;
    // получение модулей для приложения
    public static modulesMobile = () =>  `${AppApiUrls.NewDomain}api/v1/city/modules/mobile`;
    // получение профессии  по модулю
    public static modulesMobileProfs = () =>  `${AppApiUrls.NewDomain}api/v1/city/modules/mobile/profs`;
    // отправить ответ о перезвоне
    public static qualityControl = () =>  `${AppApiUrls.NewDomain}api/v1/quality_recall`;
    // получение типа валюты
    public static currencyControl = () =>  `${AppApiUrls.NewDomain}api/v1/currency`;
    // Обновление статуса ПЛАТЕЖА накладной
    public static invoicePay = () => `${AppApiUrls.NewDomain}api/v1/invoice/pay`;
    // Получение всех контрагентов
    public static reportsContragents = () => `${AppApiUrls.NewDomain}api/v1/invoice/reports/contragents`;
    // Получение списка зон
    public static zones = () => `${AppApiUrls.NewDomain}api/v1/zones`;
    // Добавление  выключение  страниц информации
    public static cityAboutPatch = () => `${AppApiUrls.NewDomain}api/v1/city/about`;
    // добавление особенностей для модулей с информацией
    public static aboutFeatures = () => `${AppApiUrls.NewDomain}api/v1/city/about_features`;
    // оновити Посещение пользователя
    public static visitUpdate = () => `${AppApiUrls.NewDomain}api/v1/user/visit/update`;

    // ЛОКАЛЬНЫЙ СерВИС ЧЕКБОКСА
    public static checkBoxGetDevices = () => `http://127.0.0.1:9020/api/devices`;
    public static checkBoxPingDevice = (id) => `http://127.0.0.1:9020/api/pos/${id}/ping`;
    public static checkBoxPurchaseDevice = (id) => `http://127.0.0.1:9020/api/pos/${id}/purchase`;

}
