import {Component, Input, OnInit} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {KitchenProduct, Product, Receipt} from './interface';
import {NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels} from '@techiediaries/ngx-qrcode';
import {AppApiUrls} from '../../app.api.urls';

@Component({
    selector: 'app-recipt',
    templateUrl: './receipt.component.html',
    styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent implements OnInit {
    elementType = NgxQrcodeElementTypes.URL;
    correctionLevel = NgxQrcodeErrorCorrectionLevels.LOW;
    value = 'https://3303.ua/reviews/?order_id=';
    allData: Receipt;
    @Input() orderId: number;
    loader: boolean;
    constructor(private http: HttpClient) {
    }

    ngOnInit(): void {
        this.loader = true;
        this.fetchAll().subscribe({
            next: json => {
                this.allData = (json as any).data;
            },
            complete: () => {
                // this.allData.totalSum = this.allData.products.reduce((a, b) => a + b.price * b.count, 0);
                this.allData.totalSum = this.allData.products.some(product => product.childs && product.childs.length > 0) ?
                    this.calculateTotalSum(this.allData.products) :
                    this.allData.products.reduce((a, b) => a + b.price * b.count, 0);
                this.allData.sale = Math.round((this.allData.totalSum - this.allData.total - this.allData.bonuses));
                // tslint:disable-next-line:max-line-length
                this.allData.salePercent = Math.round((((this.allData.totalSum - this.allData.total - this.allData.bonuses) / this.allData.totalSum) * 100));
                switch (this.allData.terminal)
                {
                    case 'cash':
                        this.allData.terminal = 'QUICK-SALE.CASH';
                        break;
                    case 'cashless':
                        this.allData.terminal = 'QUICK-SALE.CASHLESS';
                        break;
                    case 'card':
                        this.allData.terminal = 'QUICK-SALE.TERMINAL';
                        break;
                    case 'online':
                        this.allData.terminal = 'QUICK-SALE.LIQPAY';
                        break;
                }
                this.loader = false;
            }
        });
    }

    get filteredDataWithoutAdditions(): KitchenProduct[] {
        const filtered = this.allData.kitchen_products;

        const excludedIds = [924, 729, 925, 730, 728, 923];

        return filtered.filter(item => !excludedIds.includes(item.id));
    }

    get filteredDataWithoutAdditionsProducts(): Product[] {
        const filtered = this.allData.products;

        const excludedIds = [924, 925, 923];

        return filtered.filter(item => !excludedIds.includes(item.id));
    }


    get filteredData(): { id?: number; name: string; count: number }[] {
        const filtered = this.allData.products;

        const wasabiIds = [924, 729]; // Васаби и доп. васаби
        const soyIds = [925, 730]; // Соевый соус
        const allIds = [923, 728]; // Другие допы

        let wasabiTotal = 0;
        let soyTotal = 0;
        let allTotal = 0;
        const itemsMap = new Map<number, { id: number; name: string; count: number }>();

        for (const item of filtered) {
            if (wasabiIds.includes(item.id)) {
                wasabiTotal += item.count;
            } else if (soyIds.includes(item.id)) {
                soyTotal += item.count;
            } else if (allIds.includes(item.id)) {
                allTotal += item.count;
            } else {
                if (itemsMap.has(item.id)) {
                    itemsMap.get(item.id)!.count += item.count;
                } else {
                    itemsMap.set(item.id, { id: item.id, name: item.name, count: item.count });
                }
                }
        }

        const groupedItems = [];

        if (wasabiTotal > 0) {
            groupedItems.push({ name: 'Васабі', count: wasabiTotal });
        }
        if (soyTotal > 0) {
            groupedItems.push({ name: 'Соєвий соус', count: soyTotal });
        }
        if (allTotal > 0) {
            groupedItems.push({ name: 'Імбир', count: allTotal });
        }

        return groupedItems;
    }



    get filteredDataByWeight(): string {
        return this.allData.products
            .reduce((sum, item) => sum + item.weight * item.count, 0)
            .toFixed(0);
    }

    calculateTotalSum(products: any[]): number {
        let totalSum = 0;
        for (const product of products) {
            totalSum += product.price * product.count;
            if (product.childs && product.childs.length > 0) {
                totalSum += this.calculateTotalSum(product.childs);
            }
        }
        return totalSum;
    }

    fetchAll = () => {
        const params = new HttpParams()
            .set('id', String(this.orderId));
        return this.http.get(AppApiUrls.localPrint(), {
            params: {
                id: String(this.orderId)
            }
        });
    }
}
