import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Alltoasts} from '../../../toasts/alltoasts';
import {AppApiUrls} from '../../../app.api.urls';
import {saleData} from '../../interfaces';
import {tap} from 'rxjs/operators';
import {arrayOrders} from '../../packagepanel/packagepanel-leftblock/array.orders';
import {Observable} from 'rxjs';
import {WinnerList} from '../../reports/winner-list.interface';
import {DeliveryTime} from '../../../kitchen-info/kitchen-info.interface';
import {BlackListInterface} from '../../../reports/black-list/black-list.interface';
import {TranslateService} from '@ngx-translate/core';
import {logger} from "codelyzer/util/logger";
import {Util} from "leaflet";
import indexOf = Util.indexOf;

@Injectable({providedIn: 'root'})

export class NewDocumentService {
    constructor(
        private http: HttpClient,
        private AllToasts: Alltoasts,
        private translate: TranslateService) {
    }

    infoOrder: any;
    allCat: any;
    allProduct: any;
    catId = 3;
    bodyOrder: saleData[] = [];
    freeProducts;
    sum: number;
    lastAddress: any;
    count: number;
    terminals: any;
    zones: any;
    districts: any;
    places: any;
    deliveryTime: any;
    kitchenComment: any;
    additionalComment: any;
    bonuses: any = 0;
    constructorProduct: any;
    constructorProductId: number;


    calcFreeProducts(): Observable<any> {
        return this.http.post<any>(AppApiUrls.calcFreeProduct(), {
            city_id: localStorage.getItem('city'),
            products: this.bodyOrder
        });
    }


    getBlackListByPhone(phone): Observable<BlackListInterface> {
        return this.http.get<BlackListInterface>(AppApiUrls.blackList(), {
            params: {
                phone,
                city_id: localStorage.getItem('city')
            }
        });
    }

    getGiftByPhone(phone): Observable<WinnerList[]> {
        return this.http.get<WinnerList[]>(AppApiUrls.getWinnerList(), {
            params: {
                phone,
                city_id: localStorage.getItem('city')
            }
        });
    }

    insertToBase = (form, mode?, id?) => {
        let terminalType = null;
        let terminalText = 'terminal';

        this.translate.get(terminalText).subscribe({
            next: value => {
                terminalText = value;
            }
        });

        if (form.terminal) {
            terminalType = this.terminals[this.terminals.findIndex(t => t.id == form.terminal)].paid_status;
        }
        const body = {
            site_user_id: this.infoOrder?.site_user_id,
            promocode_id: this.infoOrder?.promocode_id,
            bonuses: form.bonuses,
            phone: form.phone,
            district_id: form.district,
            zone_id: form.zone,
            street: form.street,
            house: form?.house,
            attic: form.attic,
            apt: form.apt,
            floor: form.floor,
            stick: form.ord,
            stick_edu: form.edu,
            terminalcheck: (form.terminal === undefined) ? 0 : 1,
            terminal: form.terminal,
            sale: form.sale,
            pickupcheck: (form.pickup_check === true || form.personnel === true) ? 1 : 0,
            birthdaycheck: (form.birthday_check === true) ? 1 : 0,
            futurecheck: (form.datetime === undefined) ? 0 : 1,
            futuredate: (form.datetime === undefined) ? null : form.datetime.slice(0, 10),
            futuretime: (form.datetime === undefined) ? null : form.datetime.slice(11, 16),
            comment: ((form.comment === null) ? '' : form.comment) + ((form.terminal === undefined || terminalType === 1 || terminalType === 2) ? '' : '/' + terminalText) + ((form.birthday_check === false) ? '' : '/ДН'),
            author_id: localStorage.getItem('id'),
            total: this.sum,
            totalwithoutsale: this.sum + this.bodyOrder.reduce((a, b) => a + (b.sale || 0), 0),
            place: form.place,
            city: localStorage.getItem('city'),
            order: []
        };
        if (mode === 'site') {
            body[`from_order`] = id;
        }

        this.bodyOrder.forEach(item => {
            body.order.push(item);
        });
        this.freeProducts.forEach(item => {
            body.order.push(item);
        });

        return this.http.post<any>(AppApiUrls.WorkWithOrder,
            body)
            .pipe(tap(
                (val) => {
                },
                response => {
                    console.log('POST call in error', response);
                    this.AllToasts.showError();
                },
                () => {
                    this.AllToasts.showSuccess();
                }));
    }
    getInfoAboutCities = () => this.http.get(AppApiUrls.CitiesInfo(localStorage.getItem('city')));
    updateOrder = (form) => {
        const body = {
            site_user_id: this.infoOrder?.site_user_id,
            promocode_id: this.infoOrder?.promocode_id,
            bonuses: form.bonuses,
            phone: form.phone,
            district_id: form.district,
            zone_id: form.zone,
            street: form.street,
            house: form?.house,
            attic: form.attic,
            apt: form.apt,
            floor: form.floor,
            stick: form.ord,
            stick_edu: form.edu,
            terminalcheck: (form.terminal === undefined) ? 0 : 1,
            terminal: form.terminal,
            futurecheck: (form.datetime === undefined) ? 0 : 1,
            futuredate: (form.datetime === undefined) ? null : form.datetime.slice(0, 10),
            futuretime: (form.datetime === undefined) ? null : form.datetime.slice(11, 16),
            comment: form.comment,
            place: form.place,
            city: localStorage.getItem('city'),
        };
        return this.update(body, this.infoOrder.id).pipe(tap({
            complete: () => this.AllToasts.showSuccess()
        }));
    }

    update(body: any, id: number): Observable<any> {
        return this.http.put<any>(AppApiUrls.WorkWithOrder + id,
            body);
    }

    deleteOrder = (id) => {
        return this.http.delete<any>(AppApiUrls.WorkWithOrder + id)
            .subscribe(
                (val) => {
                },
                response => {
                    console.log('POST call in error', response);
                    this.AllToasts.showError();
                },
                () => {
                    this.AllToasts.showSuccess();
                });
    }
    getAllTerminals = () => {
        const body = new HttpParams()
            .set('city', localStorage.getItem('city'))
            .set('place', localStorage.getItem('place'));
        return this.http.get<any>(`${AppApiUrls.Terminals}?city_id=${localStorage.getItem('city')}`)
            .pipe(tap(
                (val) => {
                    this.terminals = val as any;
                },
                response => {
                    console.log('POST call in error', response);
                    this.AllToasts.showError();
                },
                () => {

                }));
    }

    getAllPlaces = () => {
        return this.http.get<any>(AppApiUrls.GetAllPlaces, {
            params: {
                city_id: localStorage.getItem('city')
            }
        })
            .pipe(tap(
                (val) => {
                    this.places = val as any;
                },
                response => {
                    console.log('POST call in error', response);
                    this.AllToasts.showError();
                },
                () => {

                }));
    }

    getAllDistricts = () => {
        const body = new HttpParams()
            .set('city', localStorage.getItem('city'));
        return this.http.get<any>(`${AppApiUrls.GetAllDistricts}?city_id=${localStorage.getItem('city')}`)
            .pipe(tap(
                (val) => {
                    this.districts = (val as any).data;
                },
                response => {
                    console.log('POST call in error', response);
                    this.AllToasts.showError();
                },
                () => {

                }));
    }

    getAllZones = () => {
        const body = new HttpParams()
            .set('city', localStorage.getItem('city'));
        return this.http.get<any>(`${AppApiUrls.GetAllZones}?city_id=${localStorage.getItem('city')}`)
            .pipe(tap(
                (val) => {
                    this.zones = (val as any).data;
                },
                response => {
                    console.log('POST call in error', response);
                    this.AllToasts.showError();
                },
                () => {

                }));
    }

    getAllCat = () => {
        const body = new HttpParams()
            .set('lang', 'ua')
            .set('city', localStorage.getItem('city'))
            .set('quicksale', '1');
        return this.http.get<any>(`${AppApiUrls.GetAllCategories}?city_id=${localStorage.getItem('city')}`)
            .pipe(tap(
                (val) => {
                    this.allCat = (val as any).data;
                },
                response => {
                    console.log('POST call in error', response);
                    this.AllToasts.showError();
                },
                () => {

                }));
    }
    getAllProduct = () => {
        let body = new HttpParams();
        let params = `?city_id=${localStorage.getItem('city')}`;
        if (this.catId == null) {
            body = body
                .set('city', localStorage.getItem('city'))
                .set('quicksale', String(1));
        } else {
            params += '&cat_id=' + String(this.catId);
            body = body
                .set('city', localStorage.getItem('city'))
                .set('quicksale', String(1))
                .set('cat_id', String(this.catId));
        }

        return this.http.get<arrayOrders>(AppApiUrls.ProductCity + params)
            .pipe(tap(
                (val) => {
                    this.allProduct = (val as any).data;
                },
                response => {
                    console.log('POST call in error', response);
                    this.AllToasts.showError();
                },
                () => {

                }));
    }

    getLinkedProducts(id: number): Observable<any> {
        return this.http.get<any>(AppApiUrls.linkedProducts(), {
            params: {
                product_id: id,
                city_id: localStorage.getItem('city')
            }
        });
    }


    addToOrder = (id, form, obj?, prodId?) => {
        this.constructorProductId = id;
        const indexDoc = this.bodyOrder.findIndex(t => t.product_id == id && t.part_of_set === 0 );
        const i = this.allProduct.findIndex(t => t.id == id);
        if (indexDoc <= -1 || this.bodyOrder[indexDoc].childs.length > 0) {
            this.bodyOrder.push({
                product_name: this.allProduct[i].name,
                position_in_check: this.allProduct[i].position_in_check,
                product_id: this.allProduct[i].id,
                cat_id: this.allProduct[i].cat_id,
                price: this.allProduct[i].price,
                price_id: this.allProduct[i].price_id,
                sale: this.allProduct[i].price * form.sale / 100,
                sum: this.allProduct[i].price * 1 - this.allProduct[i].price * form.sale / 100,
                quantity: 1,
                part_of_set: 0,
                confirmed: true,
                childs: []
            } as saleData);
        } else {
            this.addToQuantity(indexDoc, form);
        }
        this.TotalSum();
        this.getFreeProduct();
    }

    // tslint:disable-next-line:typedef
    getConstructorProducts(products, id, form, index, linkedProductCategory){
        const indexDoc = this.bodyOrder.findIndex(t => t.product_id == this.constructorProductId && t.part_of_set === 0 );
        if (id > -1) {
            const order = this.bodyOrder[index];
            const indexOrder = order.childs.findIndex(t => t.product_id == id && t.part_of_set === 0 );

            if (products && indexOrder <= -1) {
                order.childs.push({
                    product_name: products.name,
                    position_in_check: products.position_in_check,
                    product_id: products.id,
                    cat_id: products.cat_id,
                    price: products.price,
                    price_id: products.price_id,
                    sale: products.price * form.sale / 100,
                    sum: products.price * 1 - products.price * form.sale / 100,
                    quantity: 1,
                    part_of_set: 0,
                    childs: [],
                    linkedProductCategory
                } as saleData);
            } else if (indexOrder > -1) {
               this.addToQuantityCardOrderParts(indexOrder, form, order);
            }
            this.TotalSum();
        }
    }


    deleteProductFromCard = i => {
        this.bodyOrder.splice(i, 1);
        this.TotalSum();
        this.getFreeProduct();
    }

    deleteProductFromCardOrderParts = (i, order ) => {
        order.childs.splice(i, 1);
        this.TotalSum();
        this.getFreeProduct();
    }

    getFreeProduct(): void {
        this.freeProducts = [];
        this.calcFreeProducts().subscribe({
            next: value => {
                this.freeProducts = value;
            }
        });
    }
    changeQuantity = (indexDoc, form?) => {
        if (this.bodyOrder[indexDoc].quantity == null || this.bodyOrder[indexDoc].quantity <= 0) {
            this.bodyOrder.splice(indexDoc, 1);
        } else if (this.bodyOrder[indexDoc].sale == null || this.bodyOrder[indexDoc].sale <= 0) {
            this.bodyOrder[indexDoc].sale = 0;
            this.bodyOrder[indexDoc].sum =
                this.bodyOrder[indexDoc].quantity * this.bodyOrder[indexDoc].price - this.bodyOrder[indexDoc].sale;
        } else if (form.sale > 0 || form.sale !== null) {
            this.saleWithPercentInput(form);
        } else if (this.bodyOrder[indexDoc].sale > 0) {
            this.bodyOrder[indexDoc].sum =
                this.bodyOrder[indexDoc].quantity * this.bodyOrder[indexDoc].price - this.bodyOrder[indexDoc].sale;
        }
        this.TotalSum();
        this.getFreeProduct();
    }

    changeQuantityCardsOfParts = (index, form?, order?) => {
        if (order.childs[index]?.quantity == null || order.childs[index].quantity <= 0) {
            order.childs.splice(index, 1);
        } else if (order.childs[index].sale == null || order.childs[index].sale <= 0) {
            order.childs[index].sale = 0;
            order.childs[index].sum =
                order.childs[index].quantity * order.childs[index].price - order.childs[index].sale;
        } else if (form.sale > 0 || form.sale !== null) {
            this.saleWithPercentInput(form);
        } else if (order.childs.sale > 0) {
            order.childs[index].sum =
                order.childs[index].quantity * order.childs[index].price - order.childs[index].sale;
        }
        this.TotalSum();
    }

    changeSale = indexDoc => {
        this.bodyOrder[indexDoc].sum = this.bodyOrder[indexDoc].quantity * this.bodyOrder[indexDoc].price - this.bodyOrder[indexDoc].sale;
        this.TotalSum();
    }

    saleWithPercentInput = (form, mode?) => {
        const calculateDiscountAndSum = (obj, form) => {
            if (obj.confirmed){
                const discount = form.sale / 100;
                obj.sale = obj.quantity * obj.price * discount;
                obj.sum = (obj.quantity * obj.price) - obj.sale;
                if (obj.childs && obj.childs.length > 0) {
                    for (const child of obj.childs) {
                        calculateDiscountAndSum(child, form);
                        child.sale = child.quantity * child.price * discount;
                        child.sum = (child.quantity * child.price) - child.sale;
                    }
                }
            }
        };

        for (const obj of this.bodyOrder) {
            calculateDiscountAndSum(obj, form);
        }
        this.TotalSum();
    }

    setBonuses(bonuses): void {
        this.bonuses = bonuses;
        this.TotalSum();
    }

    getSum = () => {
        for (const obj of this.bodyOrder) {
            obj.sale = obj.sale ?? 0;
            obj.sum = obj.quantity * obj.price - obj.sale;
            if (obj.childs && obj.childs.length > 0) {
                for (const child of obj.childs) {
                    child.sale = child.sale ?? 0;
                    child.sum = child.quantity * child.price - child.sale;
                }
            }
        }
        this.TotalSum();
    }

    TotalSum = () => {
        if (JSON.parse(localStorage.getItem('cityInfo')).country.total_round === 1) {
            this.sum = Math.ceil(this.bodyOrder.reduce((a, b) => a + (b.sum || 0), 0) - this.bonuses);
        } else {
            this.sum = Math.round(this.bodyOrder.reduce((a, b) => a + (b.sum || 0), 0) * 100) / 100 - this.bonuses;
        }
        this.count = this.bodyOrder.reduce((a, b) => a + (b.quantity || 0), 0);

        this.sum += this.bodyOrder.reduce((a, b) => {
            if (b.childs) {
                a += b.childs.reduce((c, d) => c + (d.sum || 0), 0) * b.quantity;
            }
            return a;
        }, 0);
    }

    addToQuantity = (indexDoc, form?) => {
        this.bodyOrder[indexDoc].quantity++;
        this.changeQuantity(indexDoc, form);
        this.getFreeProduct();
    }

    removeFromQuantity = (indexDoc, form?) => {
        this.bodyOrder[indexDoc].quantity--;
        this.changeQuantity(indexDoc, form);
        this.getFreeProduct();
    }

    addToQuantityCardOrderParts = (indexOrder, form?, order?) => {
        order.childs[indexOrder].quantity += 1;
        this.changeQuantityCardsOfParts(indexOrder, form, order);
        this.getFreeProduct();
    }


    removeFromQuantityCardOrderParts = (indexOrder, form?, order?) => {
        order.childs[indexOrder].quantity--;
        this.changeQuantityCardsOfParts(indexOrder, form, order);
        this.getFreeProduct();
    }

    getAllDataAboutOrder = orderId => {
        return this.http.get<any>(AppApiUrls.WorkWithOrder + orderId)
            .pipe(tap(
                val => {
                },
                response => {
                    console.log('POST call in error', response);
                    this.AllToasts.showError();
                },
                () => {
                }));
    }

    getAllDataFromSite = (orderId) => {

        return this.http.get<any>(AppApiUrls.submitOrderInQS(), {
                params: {
                    order_id: orderId,
                    full: true
                }
            }
        ).pipe(tap(
            val => {
                this.infoOrder = val as any;
                this.bodyOrder = val.order as any;
            },
            response => {
                console.log('POST call in error', response);
                this.AllToasts.showError();
            },
            () => {

            }));
    }
    getDeliveryTime = () => {
        return this.http.get<DeliveryTime>(AppApiUrls.DeliveryTime(), {
            params: {
                place_id: localStorage.getItem('place')
            }
        })
            .subscribe({
                next: value => {
                    this.deliveryTime = value.time;
                    this.kitchenComment = value.kitchen_comment;
                    this.additionalComment = value.additional_comment;
                }
            });
    }

    clearAndRefreshArrays = () => {
        delete this.infoOrder;
        delete this.allCat;
        delete this.allProduct;
        delete this.bodyOrder;
        this.sum = 0;
        delete this.terminals;
        delete this.zones;
        delete this.districts;
        delete this.places;
        this.infoOrder = {};
        this.allCat = [];
        this.allProduct = [];
        this.bodyOrder = [];
        this.terminals = [];
        this.zones = [];
        this.districts = [];
        this.places = [];
        this.count = 0;
    }
    fillByPhone = phone => this.http.get<any>(AppApiUrls.FillByPhone,
        {
            params: {
                phone,
                city_id: localStorage.getItem('city')
            }
        }).pipe(tap(
        val => {

        },
        response => {
            console.log('POST call in error', response);
            this.AllToasts.showError();
        },
        () => {

        }))

    saveEditHistory(value: any): Observable<any> {
        return this.http.post(AppApiUrls.historyEditedDocuments(), value);
    }

    lastBirthday(phone): Observable<any> {
        return this.http.get(AppApiUrls.lastBirthday(), {
            params: {
                phone,
                city_id: localStorage.getItem('city')
            }
        });
    }


}
